


























@import "~vars";
@import "~bulma/sass/utilities/mixins";

.app-link {
  &--header {
    background-color: $primary;
    padding: 4rem;
    transition: background-color 0.2s ease-in;
    //mobile and tablet
    @include mobile() {
      padding: 2rem;
    }

    .image svg {
      width: 100%;
      height: 100%;

      use {
        transition: fill 0.2s ease-in;
      }
    }

    .app-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--content {
    text-align: center;
    padding: 1.5rem;

    @include mobile() {
      padding: 0.5rem;

      .title {
        font-size: $size-5;
      }
    }
  }
}

.main-navigation {
  .column:first-child .app-link:hover use {
    fill: $cyan;
  }
  .column:nth-child(2) .app-link:hover use {
    fill: $magenta;
  }
  .column:nth-child(3) .app-link:hover use {
    fill: $background-yellow;
  }
}
