


























































































@import "~vars";
.app-link.news {
  transition: height 0.3s !important;

  .app-link--header {
    position: absolute;
    top: 0;
    padding: 0;
    background-color: transparent;
    overflow: hidden;
    height: 14.5rem;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .app-link--content {
    margin-top: 14.5rem;
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 100%;
    min-height: 15rem;
    padding-bottom: 0.5rem;
  }

  .content__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
  }

  .news-link {
    margin-top: auto;
    margin-right: auto;
  }

  &.expand {
    .content__text {
      -webkit-line-clamp: initial;
    }
  }
}
