



















































@import "~vars";
@import "~bulma/sass/utilities/mixins";

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#new-portal {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1rem;
}

@include mobile() {
  .main-navigation {
    padding: 0 0.75rem;
    margin-top: 1rem;
  }

  .news-carousel {
    display: flex;
    justify-content: center;

    .card {
      max-width: 90%;
    }
  }
}

